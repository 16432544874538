@import './variables';

footer {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
}

a:hover {
  border-left: none !important;
}

.socialMedia svg {
    color: #fff;
    margin: 10px;
    font-size: 2.75rem;
    cursor: pointer;
    flex-wrap: wrap;
}

footer p {
    color: #fff;
    font-size: .75rem;
}

@media only screen and (max-width: 600px) {
  footer svg {
    font-size: 2.5rem;
  }
}