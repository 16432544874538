@import './variables';

a {
    text-decoration: none !important;
    padding: 0 1.15rem;
    font-size: 1.5rem;
    color: $secondaryTextColor;
}

a:hover {
    border-left: 4px solid #627EB5 !important;
    color: #627EB5;
}

a:active {
    border-left: 4px solid #627EB5 !important;
    color: #627EB5; 
}

.navbar-brand {
    color: $secondaryTextColor !important;
    font-weight: bolder !important;
    font-size: 2rem !important;
}

.navbar {
    padding: 0;
    display: flex;
    justify-content: space-around;
}

.navigation {
    display: flex;
}

.navbar-toggler-icon {
    background-size: 125%;
}

.navbar-toggler {
    font-size: 1.5rem !important;    
    height: 115px;
}

.MuiSvgIcon-root {
    color: $secondaryTextColor !important;
    font-size: 2.5rem !important;
}



