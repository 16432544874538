body {
    background: linear-gradient(rgba(0,0,0, 0.7), rgba(0,0,0, 0.7)), url('../assets/black_background_texture.png');
    background-repeat: repeat;
    background-position: center;
    background-size: cover;
}

.heading H1{
    color: $secondaryTextColor;
}

.btn {
    background: $btnColor;
}

.btnText {
    color: $btnTextColor;
}