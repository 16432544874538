@import './variables';



.contact {
    width: 100%;
    height: calc(100vh - 106px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    padding: 0 20px;
}

.contact h1 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 4rem;
  color: $secondaryTextColor;
  height: 70px;
}

.contactInfo h2 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 4rem;
  color: #7da1e9;
  height: 70px;
}

.about .contactInfo {
  width: 60%;
  font-size: 1.75rem;
  letter-spacing: .15rem;
  line-height: 2.15rem;
  text-align: left;
  margin: 40px auto;
}

.contactInfo {
  line-height: 1.15em;
}

.contactInfo a {
    font-size: 32px;
}

.contactInfo svg {
  font-size: 60px;
  margin: 5px;
  color: white;
}

.row {
  --bs-gutter-x: 0;
}



@media only screen and (max-width: 600px) {
  .about h1 {
    font-size: 2rem;
    font-weight: 600;
    height: 30px;
  }

  .about .contactInfo {
    margin-top: 40px;
    font-size: 20px;
    width: 80%;
    line-height: 1.25em;
  }
}