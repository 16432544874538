@import './variables';


.home {
  width: 100%;
  align-items: center;
  font-family: "Arial", sans-serif;
  
}

.about {
    width: 100%;
    height: calc(100vh - 106px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    padding: 0 20px;
}

.about h1 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 4rem;
  color: $secondaryTextColor;
  height: 70px;
}


.skills h1 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 4rem;
  color: $primaryTextColor !important;
  height: 70px;
}

.info h2 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 4rem;
  color: #7da1e9;
  height: 70px;
}

.about .info {
  width: 60%;
  font-size: 1.75rem;
  letter-spacing: .15rem;
  line-height: 2.15rem;
  text-align: left;
  margin: 40px auto;
}

.info {
  line-height: 1.15em;
}

.info svg {
  font-size: 60px;
  margin: 5px;
  color: white;
}

.row {
  --bs-gutter-x: 0;
}

.skills {
  font-size: 35px;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: $primaryColor;
  padding: 0 20px;
}

.skills h1 {
  margin-bottom: 50px;
}

.skills-list {
  list-style: none;
  width: 70%;
}

.skills-list p {
  font-size: 1rem;
}


@media only screen and (max-width: 600px) {
  .about h1 {
    font-size: 2rem;
    font-weight: 600;
    height: 30px;
  }

  .about .info {
    margin-top: 40px;
    font-size: 20px;
    width: 80%;
    line-height: 1.25em;
  }

  .skills {
    text-align: center;
  }

  .skills h1 {
    font-size: 2rem;
    font-weight: 600;
  }

  .skills-list {
    padding: 0;
    width: 100%;
  }

  .skills h2 {
    font-size: 1.75rem;
    margin-top: 10px;  
  }
}