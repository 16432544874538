@import './variables';

.experience {
    height: calc(100vh - 100px);
}

.vertical-timeline-element-title {
    color: $primaryTextColor;
}

.vertical-timeline-element-title  a {
    color: $primaryTextColor;
    cursor: pointer; 
}

.date {
    text-align: center !important;
    color: $primaryTextColor;
    font-size: 1rem;
    font-weight: 500;
}

.vertical-timeline-element-content p {
    font-size: 0.8125rem;
}


.vertical-timeline::before {
   height: calc(100vh - 106px)
}

.line-color {
    color: $secondaryColor !important;
}





