@import './Styles/Variables';
@import './Styles/Layout';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
    height: 100%;
    margin: auto;
}

.App {
  min-height: 100vh;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: #fff;
}

.heading {
  text-align: center;
}



