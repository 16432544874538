@import './variables';
@import './Layout';


.workDetails {
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.workDetails img {
  width: 900px;
  height: 540px;
  border-radius: 10px;
  margin: 60px 0 0;
}

.workDetails h5 {
  width: 40%;
  text-align: left;
}

.workDetails p {
  font-size: 2rem;
  color: $secondaryColor;
  margin-top: 30px;
  cursor: pointer;
}

.workDetails svg {
  font-size: 3.75rem;
  color: #1C2536 ;
}


@media only screen and (max-width: 600px) {
  .workDetails img {
    width: 95%;
    height: 60%;
  }
}