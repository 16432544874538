@import './variables';
@import './Layout';



.card {
    background-color: #C6D3EC;
    color: #384869;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.card-body {
    width: 288px;
    height: 250px;
    display: flex;
    flex-direction: column;
}

.card-title {
    text-align: center;
    font-size: 2rem;
    font-weight: bolder;
}

.work-list {
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 30px;
  margin: 60px auto;
}

.card-img-top {
    width: 18rem;
    height: 18rem;
}

.btn {
    margin-top: auto;    
}

